import React from 'react';
import mockupA from './img/sideBySide.png';
import mockupB from './img/welcome.png';
import logo from './img/JUSTFORAPPLE.png'
import './App.css';

function App() {
  return (
    <div className="App">
      <div>

      </div>


      <div className="mainParent">
        <div className="promoText">
          <img src={logo} className="logo" alt="logo" />
          <h1>
            Mixtape
        </h1>
          <p>
            The Instagram for music 🙌
        </p><div style={{ marginBottom: 5 }}>
            <a href="https://itunes.apple.com/us/app/mixtape-music-social-media/id1459775641?mt=8" className="iTunes"></a>

            <a href='https://play.google.com/store/apps/details?id=com.imranbey.mixtape&hl=en_US&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' className="googlePlay"></a>
          </div>

        </div>


        <div className="mockupContainer" style={{ flex: 1.5 }}>
          <img src={mockupA} className="mockupA" alt="mockupA" />
          {/* <img src={mockupB} className="mockupA" alt="mockupA" /> */}
        </div>

      </div>

    </div>
  );
}

export default App;
